const routes = [
  {
    path: "/productos",
    name: "Productos",
    component: () => import(/* webpackChunkName: "productos" */ "./Productos"),
  },
  {
    path: "/productos/:id",
    name: "Produto",
    component: () => import(/* webpackChunkName: "producto" */ "./Producto"),
    props: true,
  },
];
export default routes;
