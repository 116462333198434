const routes = [
  {
    path: "/clientes",
    name: "Clientes",
    component: () => import(/* webpackChunkName: "clientes" */ "./Clientes"),
  },
  {
    path: "/clientes/:id",
    name: "Cliente",
    component: () => import(/* webpackChunkName: "cliente" */ "./Cliente"),
    props: true,
  },
];
export default routes;
