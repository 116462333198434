const routes = [
  {
    path: "/usuarios",
    name: "Usuarios",
    component: () => import(/* webpackChunkName: "usuarios" */ "./Usuarios"),
  },
  {
    path: "/usuarios/:id",
    name: "Usuario",
    component: () => import(/* webpackChunkName: "usuario" */ "./Usuario"),
    props: true,
  },
];
export default routes;
