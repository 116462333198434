const routes = [
  {
    path: "/inventario",
    name: "Inventario",
    component: () =>
      import(/* webpackChunkName: "inventario" */ "./Inventario"),
  },
  {
    path: "/inventarios",
    name: "Inventarios",
    component: () =>
      import(/* webpackChunkName: "inventarios" */ "./Inventarios"),
  },
];
export default routes;
