<script>
import LZString144 from "./lzstring";
import Api from "./Api"; // eslint-disable-line
import { defineComponent } from "vue";
let ApiOffline = {
  name: "ApiOffline",
  data() {
    return {
      // Prefijo de la BD
      prefijo: "hb3",
      // Comprimir los datos o no?
      comprimirDatos: true,
      // Colecciones no actualizables arrojarán un error al estar en modo offline. Es imperativo que se actualicen online.
      coleccionesNoActualizables: [
        "directorio",
        "usuario",
        "producto",
        "insumo",
      ],
      // Catálogos son todas aquellas colecciones que se descargarán inmediatamente para disponibilidad offline
      catalogos: [
        "directorio",
        "usuario",
        "forma",
        "producto",
        "insumo",
        "familia",
        "cliente",
        "proveedor",
        "metodospago",
        "lineaprecio",
        "empresa",
        "almacen",
      ],
      // Colecciones caducables son las que sólo se descargarán si tienen cierta edad
      coleccionesCaducables: [
        "proyecto",
        "transformacionactividad",
        "ordencompra",
        "partida",
        "partidaoc",
        "pago",
        "gasto",
        "documentoforma",
        "facturama",
        "error",
      ],
      // La antiguedad de los datos de las colecciones que se guardarán hacia atrás
      edadColeccionesCaducables: new Date().getTime() - 1000 * 60 * 60 * 24 * 7,
      // Finalmente, el modo Offline para propagar por las funciones de esta API
      modoOffline: false,
      modoProduccion:
        process &&
        process.env &&
        process.env.NODE_ENV &&
        process.env.NODE_ENV === "production",
    };
  },
  methods: {
    uuid() {
      // TODO remplazar primer mitad del UUID con algo característico del dispositivo o del nombre de la BD
      return "xxxxxxxxxxxxxxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        let r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
    get(coleccion, id, sincrono) {
      sincrono = sincrono && true;
      coleccion = (coleccion || "").toLowerCase();
      id = id || "";
      let comprimirDatos = this.comprimirDatos;
      let res;
      //console.log("ApiOffline.get", coleccion, id, sincrono, comprimirDatos)
      let prom = new Promise(async (resolve, reject) => {
        try {
          //console.log("ApiOffline get", coleccion, id)
          let keys = Object.keys(localStorage);
          let results = [];
          for (let key of keys)
            try {
              let label = this.prefijo + "." + coleccion;
              if (id != "") label += "." + id;
              //console.log("Comparando", label, key.replace(label, '') == '')
              if (
                (key.startsWith(label) &&
                  key.replace(label + ".", "").length == 24) ||
                key == label
              ) {
                let val = localStorage.getItem(key);
                if (comprimirDatos)
                  try {
                    val = LZString144.decompress(val);
                  } catch (e) {
                    console.error(e);
                  }
                val = JSON.parse(val);
                //console.log("D? ApiOffline", key, val)
                results.push(val);
              }
            } catch (e) {
              console.error("EKEYPROC ApiOffline", coleccion, key, e);
            }
          //console.log("R? ApiOffline", results)
          if (!sincrono) await Api.wait(50);
          if (results.length == 0)
            throw new Error("APIOFFLINE Objeto no encontrado: " + coleccion);
          if (id != "" && results.length == 1) {
            res = results[0];
            resolve(res);
          } else {
            res = results;
            resolve(res);
          }
        } catch (e) {
          console.warn("EAPIOFFLINENOTFOUND", coleccion, id, e);
          if (id == "") {
            res = [];
            resolve(res); // Devolver coleccion vacia, es valor posible
          } else {
            res = {
              // Devolver objeto no encontrado
              error: e,
            };
            reject(res);
          }
        }
      });
      if (!sincrono) return prom;
      else return res;
    },
    async find(coleccion, params, opciones) {
      console.log("ApiOffline.find", coleccion, params, opciones);
      let resultados = await this.get(coleccion);
      params = params || [];
      opciones = opciones || {};
      // Filtrar por parámetros
      if (params.length > 0) {
        for (let parametro of params) {
          // Parametrizar el filtro
          let campo = parametro.split(",")[0];
          let operador = parametro.split(",")[1];
          let valor = parametro.split(",")[2];
          // Limpieza de valores
          if (
            ["gt", "gte", "lt", "lte"].indexOf(operador) >= 0 &&
            !isNaN(valor)
          )
            valor = parseFloat(valor);
          if (valor == "null") valor = null;
          // Aplicar el filtro
          resultados = resultados.filter((r) => {
            let mostrar = true;
            let valorEnCampo = r[campo];
            switch (operador) {
              case "eq":
              case "equals":
                mostrar = valorEnCampo == valor;
                break;
              case "gt":
                mostrar = valorEnCampo > valor;
                break;
              case "gte":
                mostrar = valorEnCampo >= valor;
                break;
              case "lt":
                mostrar = valorEnCampo < valor;
                break;
              case "lte":
                mostrar = valorEnCampo <= valor;
                break;
              case "contains":
              case "cs":
                mostrar = valorEnCampo.indexOf(valor) >= 0;
                break;
              case "ne":
                mostrar = valorEnCampo != valor;
                break;
              default:
                console.warn("No se reconoce el operador", operador);
                mostrar = false;
                break;
            }
            return mostrar;
          });
        }
      }
      // Configuracion de resultados
      if (opciones.sort) {
        let sort = opciones.sort;
        if (typeof sort == "string") sort = [sort];
        for (let ordenable of sort) {
          let campo = ordenable.split(",")[0];
          let sentido = ordenable.split(",")[1];
          resultados = resultados.sort((a, b) => {
            if (sentido == 1 || sentido == "1")
              return a[campo] > b[campo] ? 1 : -1;
            else return a[campo] < b[campo] ? 1 : -1;
          });
        }
      }
      if (opciones.skip && !isNaN(opciones.skip))
        resultados = resultados.splice(opciones.skip, resultados.length);
      if (opciones.limit && !isNaN(opciones.limit))
        resultados = resultados.slice(0, opciones.limit);
      // Listo
      return Promise.resolve(resultados);
    },
    save(coleccion, obj) {
      coleccion = (coleccion || "").toLowerCase();
      obj = obj || {};
      let comprimirDatos = this.comprimirDatos;
      //console.log("savelocal", coleccion, obj)
      return new Promise((resolve, reject) => {
        if (typeof obj != "object")
          reject({
            error: "El recurso a guardar no es un objeto",
          });
        else if (coleccion == "error")
          resolve({
            msg: "No se sincronizarán errores en modo Offline",
          });
        else
          try {
            let id = obj && obj._id && obj._id != "" ? obj._id : this.uuid();
            obj._id = id;
            let label = this.prefijo + "." + coleccion + "." + id;
            obj.version = new Date().getTime();
            let resource = JSON.stringify(obj);
            if (comprimirDatos) resource = LZString144.compress(resource);
            localStorage.setItem(label, resource);
            resolve(obj);
          } catch (e) {
            console.error("EAPIOFFLINESAVE", e);
            reject({
              error: "No se pudo guardar localmente",
              raw: e,
            });
          }
      });
    },
    delete(coleccion, id) {
      return new Promise((resolve, reject) => {
        try {
          let label = this.prefijo + "." + coleccion + "." + id;
          localStorage.removeItem(label);
          resolve(true);
        } catch (e) {
          console.error("EAPIOFFLINEDELETE", e);
          reject(true);
        }
      });
    },
    async deleteMulti(coleccion, lista) {
      lista = lista || [];
      try {
        for (let item of lista) {
          await this.delete(coleccion, item);
        }
        return Promise.resolve(true);
      } catch (e) {
        return Promise.reject(true);
      }
    },
    async setModoOffline() {
      this.modoOffline = false;
      try {
        // Descargar catalogos
        for (let catalogo of this.catalogos) {
          let objetos = await Api.get(catalogo);
          console.log("Guardando catalogos desde la nube", catalogo, objetos);
          for (let item of objetos) if (item) this.save(catalogo, item);
        }
        // Descargar otras colecciones caducables
        let coleccionesCaducables = JSON.parse(
          JSON.stringify(this.coleccionesCaducables)
        );
        coleccionesCaducables.push("error");
        for (let coleccionCaducable of coleccionesCaducables) {
          let objetosCaducables = await Api.find(coleccionCaducable, [
            "fecha,gte," + this.edadColeccionesCaducables,
          ]);
          console.log("Guardando colección caducable", objetosCaducables);
          for (let objeto of objetosCaducables) {
            await this.save(coleccionCaducable, objeto);
          }
        }
        console.log("Colecciones caducables guardadas ");
        localStorage.setItem("vueonic6.modoOffline", 1);
        this.modoOffline = true;
        return true;
      } catch (e) {
        console.error("EsetModoOffline", e);
        return false;
      }
    },
    async unsetModoOffline() {
      console.log("Sincronizando dispositivo con la nube privada");
      this.modoOffline = false;
      Api.modoOffline = false;
      // Subir colecciones caducables
      try {
        let objetosGuardados = [];
        let forzarbd = true;
        for (let catalogoCaducable of this.coleccionesCaducables) {
          let objetosCaducables = await this.get(catalogoCaducable);
          console.log(
            "Considerando guardar",
            catalogoCaducable,
            objetosCaducables.length
          );
          for (let objeto of objetosCaducables) {
            console.log(
              "Guardando en API",
              this.modoOffline,
              catalogoCaducable,
              objeto
            );
            let objetoAntiguo = {};
            let objetoEsReemplazable = true;
            try {
              objetoAntiguo = await Api.get(catalogoCaducable, objeto._id);
            } catch (e) {
              console.error(e);
            }
            if (
              !(
                objetoAntiguo &&
                objetoAntiguo._id &&
                (objetoAntiguo.version || 0) < objeto.version
              )
            )
              objetoEsReemplazable = false;
            if (!objetoAntiguo._id)
              // No existía
              objetoEsReemplazable = true;
            if (objetoEsReemplazable) {
              let reemplazador = await Api.save(
                catalogoCaducable,
                objeto,
                forzarbd
              );
              objetosGuardados.push(reemplazador);
              // Eliminar de una vez el objeto local
              await ApiOffline.delete(catalogoCaducable, objeto._id);
            }
          }
        }
        console.log("Catalogos caducables guardados", objetosGuardados);
        // Eliminar las colecciones locales
        for (let catalogo of this.catalogos.concat(
          this.coleccionesCaducables
        )) {
          let coleccionlocal = await this.get(catalogo);
          coleccionlocal = coleccionlocal.map((c) => {
            return (c || {})._id;
          });
          let u = await this.deleteMulti(catalogo, coleccionlocal);
          console.log("Guardada colección local caducable", catalogo, u);
        }
        // Comparar catalogos con versión
        // Subir catalogos
        this.modoOffline = false;
        localStorage.setItem("vueonic6.modoOffline", 0);
        return true;
      } catch (e) {
        return false;
      }
    },
  },
  async created() {
    this.modoOffline =
      (localStorage.getItem("vueonic6.modoOffline") || "") == "1";
    console.log("Api Offline montada. Disponible:", this.modoOffline, Api);
  },
};
export default defineComponent(ApiOffline);
</script>
