const routes = [
  {
    path: "/gastos",
    name: "Gastos",
    component: () => import(/* webpackChunkName: "gastos" */ "./Gastos"),
  },
  {
    path: "/gastos/:id",
    name: "Gasto",
    component: () => import(/* webpackChunkName: "gasto" */ "./Gasto"),
    props: true,
  },
];
export default routes;
