const routes = [
  {
    path: "/lotes",
    name: "Lotes",
    component: () => import(/* webpackChunkName: "lotes" */ "./Lista"),
  },
  {
    path: "/lotes/:id",
    name: "Lote",
    component: () => import(/* webpackChunkName: "lote" */ "./Lote"),
    props: true,
  },
];
export default routes;
