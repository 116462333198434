const routes = [
  {
    path: "/pedidos",
    name: "Pedidos",
    component: () => import(/* webpackChunkName: "pedidoslista" */ "./Lista"),
  },
  {
    path: "/pedidos/:id",
    name: "Pedido",
    component: () => import(/* webpackChunkName: "pedidover" */ "./Pedido"),
    props: true,
  },
];
export default routes;
