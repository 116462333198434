const routes = [
  {
    path: "/reportes",
    name: "Reportes",
    component: () => import(/* webpackChunkName: "reportes" */ "./Reportes"),
  },
  {
    path: "/reportes/estadoderesultados",
    name: "EstadoDeResultados",
    component: () =>
      import(
        /* webpackChunkName: "reporteestadoderesultados" */ "./EstadoDeResultados"
      ),
  },
  {
    path: "/reportes/dinamico",
    name: "ReporteDinamico",
    component: () =>
      import(/* webpackChunkName: "reportesdinamico" */ "./Dinamico"),
  },
];
export default routes;
