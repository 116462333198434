<template>
  <div>
    <ion-menu-toggle>
      <ion-list>
        <ion-item lines="none">
          <ion-label color="medium">
            <h1>Operación</h1>
          </ion-label>
        </ion-item>
        <ion-item router-link="/pedidos">
          <ion-label>
            <h2>Pedidos</h2>
          </ion-label>
        </ion-item>
        <ion-item router-link="/lotes">
          <ion-label>
            <h2>Lotes</h2>
          </ion-label>
        </ion-item>
        <ion-item router-link="/gastos">
          <ion-label>
            <h2>Gastos</h2>
          </ion-label>
        </ion-item>
        <ion-item router-link="/inventario">
          <ion-label>
            <h2>Inventario</h2>
          </ion-label>
        </ion-item>
        <ion-item router-link="/inventarios">
          <ion-label>
            <h2>Inventarios realizados</h2>
          </ion-label>
        </ion-item>
        <ion-item router-link="/reportes">
          <ion-label>
            <h2>Reportes</h2>
          </ion-label>
        </ion-item>
        <div v-if="usuario.rol == 'administrador'">
          <ion-item lines="none">
            <ion-label color="medium">
              <h1>Configuración</h1>
            </ion-label>
          </ion-item>
          <!--ion-item router-link="/configuracion">
            <ion-label>
              <h2>Configuración global</h2>
            </ion-label>
          </ion-item-->
          <ion-item router-link="/productos">
            <ion-label>
              <h2>Productos</h2>
            </ion-label>
          </ion-item>
          <ion-item router-link="/tiposproducto">
            <ion-label>
              <h2>Tipos de producto</h2>
            </ion-label>
          </ion-item>
          <ion-item router-link="/clientes">
            <ion-label>
              <h2>Clientes</h2>
            </ion-label>
          </ion-item>
          <ion-item router-link="/usuarios">
            <ion-label>
              <h2>Usuarios</h2>
            </ion-label>
          </ion-item>
          <ion-item router-link="/lineasdeprecio">
            <ion-label>
              <h2>Líneas de precios</h2>
            </ion-label>
          </ion-item>
          <ion-item v-if="!modoProduccion" router-link="/importar">
            <ion-label>
              <h2>Importar</h2>
            </ion-label>
          </ion-item>
          <ion-item v-if="!modoProduccion" router-link="/integridad">
            <ion-label>
              <h2>Integridad</h2>
            </ion-label>
          </ion-item>
        </div>
      </ion-list>
    </ion-menu-toggle>
  </div>
</template>
<script>
import Api from "@/components/Api/Api";
import { IonList, IonItem, IonLabel, IonMenuToggle } from "@ionic/vue";
export default {
  name: "HbMenu",
  components: { IonList, IonItem, IonLabel, IonMenuToggle },
  props: {
    usuario: {
      type: Object,
      default: function () {
        return {};
      },
    },
    empresa: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      appName: Api.appName,
      modoProduccion: Api.modoProduccion,
    };
  },
  methods: {
    recargar() {
      setTimeout((_) => {
        window.location.reload();
      }, 500);
    },
    toggleModoDark(val) {
      this.modoDark = val;
      document.body.classList.toggle("dark", val);
      Api.saveLocal("modoDark", val == true ? "si" : "no");
    },
  },
  mounted() {
    console.log("Menu creado");
    this.modoDark = Api.fetchLocal("modoDark") == "si" ? true : false;
    if (this.modoDark == true) this.toggleModoDark(true);
  },
};
</script>
