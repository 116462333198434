const routes = [
  {
    path: "/importar",
    name: "Importar",
    component: () => import(/* webpackChunkName: "importar" */ "./Importar"),
  },
  {
    path: "/integridad",
    name: "Integridad",
    component: () =>
      import(/* webpackChunkName: "integridad" */ "./Integridad"),
  },
];
export default routes;
