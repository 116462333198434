import { createRouter, createWebHashHistory } from "@ionic/vue-router";
//import { RouteRecordRaw } from "vue-router";

import PedidosRouter from "@/components/Pedidos";
import LotesRouter from "@/components/Lotes";
import TiposproductoRouter from "@/components/Tiposproducto";
import LineasprecioRouter from "@/components/Lineasprecio";
import ClientesRouter from "@/components/Clientes";
import InventarioROuter from "@/components/Inventario";
import ReportesRouter from "@/components/Reportes";
import ProductosRouter from "@/components/Productos";
import UsuariosRouter from "@/components/Usuarios";
import GastosRouter from "@/components/Gastos";
import ConfiguracionRouter from "@/components/Configuracion";
//*/
//let routes: Array<RouteRecordRaw> = [
let routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/components/Login/Login.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/components/Login/Login.vue"),
  },
  {
    path: "/login/:brand",
    name: "LoginBrand",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/components/Login/Login.vue"),
    props: true,
  },
  {
    path: "/hola",
    name: "HelloWorld",
    component: () =>
      import(/* webpackChunkName: "hola" */ "@/components/HelloWorld.vue"),
  },
  {
    path: "/pinlock",
    name: "PinLock",
    component: () =>
      import(/* webpackChunkName: "pinlock" */ "@/components/PinPad.vue"),
  },
  {
    path: "/sesiondesdelink/:usr/:modulo",
    name: "SesionDesdeLink",
    component: () =>
      import(
        /* webpackChunkName: "sesiondesdelink" */ "@/components/HelloWorld.vue"
      ),
    props: true,
  },
];

for (const rutasModulo of [
  PedidosRouter,
  LotesRouter,
  TiposproductoRouter,
  LineasprecioRouter,
  ClientesRouter,
  InventarioROuter,
  ReportesRouter,
  ProductosRouter,
  UsuariosRouter,
  GastosRouter,
  ConfiguracionRouter,
  //*/
]) {
  routes = [...routes, ...(<[]>rutasModulo)];
}
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
